import { LinesChart } from "@/app/components/charts";
import { ChartMetrics } from "@/app/types";
import { Card, CardBody } from "@nextui-org/react";
import { ReactNode } from "react";

interface ChartCardProps {
  title: string;
  subtitle: string;
  icon: ReactNode;
  iconBgColor: string;
  chartMetrics: ChartMetrics;
  barColors: string[];
}

export const ChartCard = ({
  title,
  subtitle,
  icon,
  iconBgColor,
  chartMetrics,
  barColors,
}: ChartCardProps) => (
  <Card className="border-none shadow-sm bg-white dark:bg-gray-800">
    <CardBody className="p-6">
      <div className="flex items-center gap-4 mb-6">
        <div className={`${iconBgColor} p-2 rounded-lg`}>{icon}</div>
        <div>
          <h3 className="text-lg font-medium font-open-sans">{title}</h3>
          <p className="text-sm text-gray-500">{subtitle}</p>
        </div>
      </div>
      <LinesChart
        data={chartMetrics.datasets.map((dataset) => ({
          labels: chartMetrics.labels,
          data: dataset.data,
        }))}
        barColors={barColors}
        legendLabels={chartMetrics.datasets.map((dataset) => dataset.label)}
      />
    </CardBody>
  </Card>
);
