import { DoughnutChart } from "@/app/components/charts";
import { PublicUserMetadata } from "@/app/types";
import { Card, CardBody } from "@nextui-org/react";
import { CreditCard } from "lucide-react";
import { CreditTypeBar } from "./credit-type-bar.component";

interface CreditsUsageChartProps {
  creditUsage: {
    email: number;
    phone: number;
  };
  totalCreditsUsed: number;
  userMetadata: PublicUserMetadata;
}

export const CreditsUsageChart = ({
  creditUsage,
  totalCreditsUsed,
  userMetadata,
}: CreditsUsageChartProps) => (
  <Card className="border-none bg-white shadow-sm dark:bg-gray-800">
    <CardBody className="p-6">
      {/* Chart header */}
      <div className="mb-6 flex items-center gap-4">
        <div className="rounded-lg bg-blue-500 p-2">
          <CreditCard size={20} className="text-white" />
        </div>
        <div>
          <h3 className="font-open-sans text-lg font-medium">Credits Used</h3>
          <p className="text-sm text-gray-500">
            Email and Phone credits breakdown
          </p>
        </div>
      </div>

      {/* Credits breakdown */}
      <div className="mb-6 space-y-4">
        <CreditTypeBar
          type="Email"
          color="bg-blue-500"
          value={creditUsage.email ?? 0}
          total={userMetadata?.credits?.email ?? 0}
        />
        <CreditTypeBar
          type="Phone"
          color="bg-green-500"
          value={creditUsage.phone ?? 0}
          total={userMetadata?.credits?.phone ?? 0}
        />
      </div>

      {/* Doughnut chart */}
      <DoughnutChart
        backgroundColor={["#3E8AFF", "#00F03E"]}
        hoverBackgroundColor={["#3E8AFF", "#00F03E"]}
        data={[creditUsage.email ?? 0, creditUsage.phone ?? 0]}
        middleNumber={`${totalCreditsUsed}`}
        centerTitle="credits used"
        width={160}
        height={160}
        numberSize={20}
      />
    </CardBody>
  </Card>
);
