import {
  ArcElement,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  defaults,
  Legend,
  Plugin,
  Tooltip,
} from "chart.js/auto";
import React, { useContext, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { ThemeContext } from "../../context/theme-context";

ChartJS.register(ArcElement, Tooltip, Legend);

interface CenterPluginOptions {
  content?: string;
  titleContent?: string;
  color?: string;
  font?: {
    size?: number;
  };
  titleFont?: {
    size?: number;
    family?: string;
  };
  divisionValue?: number;
}

interface CustomChartOptions extends ChartOptions<"doughnut"> {
  plugins: {
    center: CenterPluginOptions;
  } & ChartOptions<"doughnut">["plugins"];
}

// Custom plugin to draw text in the center
const centerPlugin: Plugin<"doughnut"> = {
  id: "centerPlugin",
  beforeDraw: (chart) => {
    const { ctx, chartArea } = chart;
    const options = chart.options as CustomChartOptions;
    if (!chartArea) return;

    const { width, height } = chartArea;
    const centerConfig = options.plugins.center;
    const divisionValue = centerConfig.divisionValue || 2;
    const centerX = width / 2;
    let centerY = height / divisionValue;

    const numberText = centerConfig.content || "25%";
    const titleText = centerConfig.titleContent || "";
    const fontSize = centerConfig.font?.size || 36;
    const titleFontSize = centerConfig.titleFont?.size || 18;
    const color = centerConfig.color || "#fff";

    ctx.save();
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    if (titleText) {
      centerY -= titleFontSize / 2;
    }

    ctx.font = `${fontSize}px Arial`;
    ctx.fillStyle = color;
    ctx.fillText(numberText, centerX, centerY);

    if (titleText) {
      ctx.font = `${titleFontSize}px Arial`;
      ctx.fillText(titleText, centerX, centerY + titleFontSize * 1.5);
    }

    ctx.restore();
  },
};

interface DoughnutChartProps {
  backgroundColor: string[];
  hoverBackgroundColor: string[];
  data: number[];
  middleNumber: string;
  centerTitle?: string;
  width?: number;
  height?: number;
  numberSize?: number;
  divisionValue?: number;
  spacing?: number;
  label?: string;
  labels?: string[];
  text?: string;
}

export const DoughnutChart: React.FC<DoughnutChartProps> = ({
  backgroundColor,
  hoverBackgroundColor,
  data,
  middleNumber,
  centerTitle = "",
  width = 222,
  height = 222,
  numberSize = 36,
  divisionValue = 2,
  spacing = 10,
  label,
  labels,
  text,
}) => {
  const { theme } = useContext(ThemeContext);
  const centerTextColor = theme === "dark" ? "#FFFFFF" : "#171663";

  const chartData: ChartData<"doughnut"> = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor,
        borderColor: backgroundColor.map((color) =>
          color.replace("rgba", "rgba(0, 0, 0, 0.3)")
        ),
        borderWidth: 1,
        spacing: spacing,
        borderRadius: 20,
      },
    ],
  };

  const options: CustomChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "82%",
    hover: {
      mode: "nearest",
      intersect: true,
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      center: {
        content: middleNumber,
        ...(centerTitle && { titleContent: centerTitle }),
        color: centerTextColor,
        font: {
          size: numberSize,
        },
        titleFont: {
          size: 16,
          family: "Open Sans",
        },
        divisionValue: divisionValue,
      },
    },
  };

  useEffect(() => {
    defaults.font.family = "Open Sans";
  }, []);

  return (
    <div className="mt-5 flex items-center justify-center">
      <div
        style={{ width: `${width}px`, height: `${height}px` }}
        className="relative"
      >
        <Doughnut data={chartData} options={options} plugins={[centerPlugin]} />
        {text && (
          <p className="absolute bottom-12 left-0 w-full text-center font-open-sans text-sm font-medium">
            {text}
          </p>
        )}
      </div>
    </div>
  );
};
