import { Card, CardBody } from "@nextui-org/react";
import { ReactNode } from "react";

interface MetricsCardProps {
  title: string;
  amount: string;
  icon: ReactNode;
  change?: string | null;
  changeIcon?: ReactNode;
  color: string;
  iconBg: string;
}

export const MetricsCard = ({
  title,
  amount,
  icon,
  change,
  changeIcon,
  color,
  iconBg,
}: MetricsCardProps) => (
  <Card className={`border-none shadow-sm ${color}`}>
    <CardBody className="p-5">
      <div className="flex justify-between items-start mb-4">
        <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
          {title}
        </span>
        <div className={`p-2 rounded-lg ${iconBg} text-white`}>{icon}</div>
      </div>
      <div className="space-y-2">
        <div className="flex items-center gap-2">
          <span className="text-2xl font-semibold text-gray-900 dark:text-white">
            {amount}
          </span>
          {changeIcon}
        </div>
        {change && (
          <p className="text-xs text-gray-500 dark:text-gray-400">{change}</p>
        )}
      </div>
    </CardBody>
  </Card>
);
