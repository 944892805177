import { ChartMetrics } from "@/app/types";

export const prepareChartMetrics = (data: any[]): ChartMetrics => {
  if (!data || data.length === 0) {
    return {
      labels: ["No data"],
      datasets: [{ label: "No data", data: [0] }],
    };
  }

  const labels = data.map((item) => item._id);
  const datasets = Object.keys(data[0])
    .filter((key) => key !== "_id")
    .map((key) => ({
      label: key.charAt(0).toUpperCase() + key.slice(1),
      data: data.map((item) => item[key]),
    }));

  return { labels, datasets };
};
