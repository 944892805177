"use client";
import { useUser } from "@clerk/nextjs";
import { BookOpen, CreditCard, LineChart, Smartphone } from "lucide-react";
import Image from "next/image";
import {
  ChartCard,
  CreditsUsageChart,
  DashboardHeader,
  MetricsCard,
  Widgets,
} from "../components/dashboard";
import { Error } from "../components/error.component";
import { Loader } from "../components/loader.component";
import { useDashboard, useMetrics } from "../hooks";
import { DashboardReport, PublicUserMetadata } from "../types";
import { prepareChartMetrics } from "../utils/chart-utils";

export default function Dashboard() {
  const { user } = useUser();
  const { dashboardReport, isLoading, error, dateRange, setDateRange } =
    useDashboard();
  const { formatNumber, determineChangeIcon } = useMetrics();

  if (isLoading) return <Loader />;
  if (error) return <Error error={error} />;

  const cardMetrics = (summary?: DashboardReport) =>
    !summary
      ? []
      : [
          {
            title: "Credits used",
            amount: formatNumber(summary.credits_used),
            icon: <CreditCard size={17} />,
            change: summary.averages
              ? `On average, ${formatNumber(
                  summary.averages.credits_used_per_day
                )} credits were used per day.`
              : null,
            changeIcon: determineChangeIcon(
              summary.credits_used,
              summary.averages ? summary.averages.credits_used_per_day : 0
            ),
            color: "bg-blue-50 dark:bg-blue-900/20",
            iconBg: "bg-blue-500",
          },
          {
            title: "Phone credits used",
            amount: formatNumber(summary.phone_credits_used),
            change: summary.averages
              ? `On average, ${formatNumber(
                  summary.averages.phone_credits_per_day
                )} phone credits were used per day.`
              : null,
            changeIcon: determineChangeIcon(
              summary.phone_credits_used,
              summary.averages ? summary.averages.phone_credits_per_day : 0
            ),
            icon: <Smartphone size={17} />,
            color: "bg-green-50 dark:bg-green-900/20",
            iconBg: "bg-green-500",
          },
          {
            title: "Leads created",
            amount: formatNumber(summary.leads_created),
            change: summary.averages
              ? `On average, ${formatNumber(
                  summary.averages.leads_created_per_day
                )} leads were created per day.`
              : null,
            changeIcon: determineChangeIcon(
              summary.leads_created,
              summary.averages ? summary.averages.leads_created_per_day : 0
            ),
            icon: <BookOpen size={17} />,
            color: "bg-purple-50 dark:bg-purple-900/20",
            iconBg: "bg-purple-500",
          },
          {
            title: "Lead success rate",
            amount: `${(summary.lead_success_rate * 100).toFixed(2)}%`,
            icon: <LineChart size={17} />,
            color: "bg-yellow-50 dark:bg-yellow-900/20",
            iconBg: "bg-yellow-500",
          },
        ];

  return (
    <div className="w-full max-w-[1280px] mx-auto px-4 py-8 dark:text-white">
      <DashboardHeader dateRange={dateRange} onDateRangeChange={setDateRange} />

      <div className="mb-8">
        <Widgets />
      </div>

      <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4 mb-8">
        {cardMetrics(dashboardReport).map((metric, index) => (
          <MetricsCard key={index} {...metric} />
        ))}
      </div>

      <div className="grid lg:grid-cols-2 gap-6 mb-8">
        <CreditsUsageChart
          creditUsage={dashboardReport?.credit_usage ?? { email: 0, phone: 0 }}
          totalCreditsUsed={dashboardReport?.credits_used ?? 0}
          userMetadata={user?.publicMetadata as PublicUserMetadata}
        />

        <ChartCard
          title="PII Revealed"
          subtitle="Details revealed by type over time"
          icon={
            <img src="/assets/icons/Eye.svg" alt="PII" className="w-5 h-5" />
          }
          iconBgColor="bg-purple-500"
          chartMetrics={prepareChartMetrics(
            dashboardReport?.pii_revealed_breakdown ?? []
          )}
          barColors={["#3284FF", "#00F03E"]}
        />
      </div>

      {/* Bottom charts */}
      <div className="grid lg:grid-cols-2 gap-6">
        <ChartCard
          title="CRM Exports"
          subtitle={`Users exported to CRM for ${dateRange.toLowerCase()}`}
          icon={
            <Image
              src="/assets/icons/UserPlus.svg"
              alt="CRM"
              className="w-5 h-5"
              width={20}
              height={20}
            />
          }
          iconBgColor="bg-green-500"
          chartMetrics={prepareChartMetrics(dashboardReport?.crm_exports ?? [])}
          barColors={["#3284FF"]}
        />

        <ChartCard
          title="Credits Deducted"
          subtitle={`Total credits deducted for ${dateRange.toLowerCase()}`}
          icon={
            <Image
              src="/assets/icons/Coins.svg"
              alt="Credits"
              className="w-5 h-5"
              width={20}
              height={20}
            />
          }
          iconBgColor="bg-yellow-500"
          chartMetrics={prepareChartMetrics(
            dashboardReport?.credits_deducted ?? []
          )}
          barColors={["#00F03E", "#3284FF"]}
        />
      </div>
    </div>
  );
}
