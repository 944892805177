"use client";
import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
} from "chart.js/auto";
import { useContext, useEffect, useMemo, useRef } from "react";
import { ThemeContext } from "../../context/theme-context";

ChartJS.register(BarElement, CategoryScale, LinearScale);

interface DatasetItem {
  labels: string[];
  data: number[];
}

interface LinesChartProps {
  data: DatasetItem[];
  barColors?: string[];
  showGridLines?: boolean;
  legendLabels?: string[];
}

export const LinesChart = ({
  data,
  barColors = ["#3284FF"],
  showGridLines = true,
  legendLabels = [],
}: LinesChartProps) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const { theme } = useContext(ThemeContext);

  const datasets = useMemo<ChartData<"bar">["datasets"]>(() => {
    if (!data.length) return [];

    return data.map((dataset, index) => ({
      ...dataset,
      backgroundColor: barColors[index] || "#3284FF",
      borderRadius: 20,
      categoryPercentage: 0.7,
      barPercentage: 0.7,
      label: legendLabels[index] || `Dataset ${index + 1}`,
    }));
  }, [data, barColors, legendLabels]);

  useEffect(() => {
    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext("2d");
    if (!ctx) return;

    const gridLineColor = theme === "dark" ? "#FFFFFF1A" : "rgba(0, 0, 0, 0.1)";

    const config: ChartOptions<"bar"> = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
            // drawBorder: false,
          },
          stacked: false,
        },
        y: {
          grid: {
            display: showGridLines,
            // drawBorder: false,
            // borderDash: [1, 1],
            color: gridLineColor,
          },
          ticks: {
            stepSize: 5,
            callback: function (value) {
              return [0, 5, 10, 15, 20, 25].includes(Number(value))
                ? value
                : "";
            },
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            padding: 20,
            font: {
              size: 12,
              family: "Open Sans",
            },
            color: theme === "dark" ? "#FFFFFF" : "#000000",
          },
        },
      },
    };

    const chartData: ChartData<"bar"> = {
      labels: data[0]?.labels || [],
      datasets: datasets,
    };

    const myChart = new ChartJS(ctx, {
      type: "bar",
      data: chartData,
      options: config,
    });

    return () => {
      myChart.destroy();
    };
  }, [theme, datasets, data, showGridLines]);

  return (
    <div className="h-[400px] w-full p-3">
      <div className="h-full w-full font-open-sans text-[14px] leading-[21px] text-opacity-[0.4]">
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};
