import {
  ArcElement,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  Tooltip,
} from "chart.js/auto";
import React, { useContext, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { ThemeContext } from "../../context/theme-context";

ChartJS.register(ArcElement, Tooltip, Legend);

interface PieChartProps {
  backgroundColor: string[];
  hoverBackgroundColor: string[];
  data: number[];
  labels: string[];
  width?: number;
  height?: number;
}

export const PieChart: React.FC<PieChartProps> = ({
  backgroundColor,
  hoverBackgroundColor,
  data,
  labels,
  width = 222,
  height = 222,
}) => {
  const { theme } = useContext(ThemeContext);
  const textColor = theme === "dark" ? "#FFFFFF" : "#171663";

  const chartData: ChartData<"pie"> = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor,
        borderColor: backgroundColor.map((color) =>
          color.replace("rgba", "rgba(0, 0, 0, 0.3)")
        ),
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<"pie"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: textColor,
          font: {
            family: "Open Sans",
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = context.formattedValue;
            const total = context.dataset.data.reduce(
              (a, b) => (a as number) + (b as number),
              0
            ) as number;
            const percentage = ((context.parsed / total) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  useEffect(() => {
    ChartJS.defaults.font.family = "Open Sans";
  }, []);

  return (
    <div className="mt-5 flex items-center justify-center">
      <div
        style={{ width: `${width}px`, height: `${height}px` }}
        className="relative"
      >
        <Pie data={chartData} options={options} />
      </div>
    </div>
  );
};
