import { DateRange } from "@/app/types";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { ChevronDown } from "lucide-react";

interface DashboardHeaderProps {
  dateRange: DateRange;
  onDateRangeChange: (range: DateRange) => void;
}

const dateRangeOptions: DateRange[] = [
  "Today",
  "Last week",
  "Last month",
  "Last 60 days",
  "Last 90 days",
];

export const DashboardHeader = ({
  dateRange,
  onDateRangeChange,
}: DashboardHeaderProps) => (
  <div className="flex items-center justify-between mb-8">
    <div className="flex items-center gap-4">
      <div className="bg-blue-500 p-3 rounded-xl">
        <img
          src="/assets/icons/dashboard.svg"
          alt="Dashboard"
          className="w-8 h-8"
        />
      </div>
      <div>
        <h1 className="text-xl font-medium mb-1 font-open-sans">Dashboard</h1>
        <p className="text-xs text-gray-500 dark:text-gray-400">
          Track your usage and performance metrics
        </p>
      </div>
    </div>
    <Dropdown>
      <DropdownTrigger>
        <Button
          variant="flat"
          className="h-9 bg-gray-50 dark:bg-gray-800 text-xs"
          endContent={<ChevronDown className="w-4 h-4" />}
        >
          {dateRange}
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Date range options"
        className="dark:bg-[#03071C]"
        onAction={(key) => onDateRangeChange(key as DateRange)}
      >
        {dateRangeOptions.map((option) => (
          <DropdownItem key={option} className="text-sm">
            {option}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  </div>
);
