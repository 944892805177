import { ThemeContext } from "@/app/context/theme-context";
import { Button, Card, CardBody } from "@nextui-org/react";
import { Plus } from "lucide-react";
import Image from "next/image";
import { useContext } from "react";

export const Widgets = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="flex md:flex-row flex-col gap-5">
      {/* Download Extension Card */}
      <Card
        className="flex-1 relative min-h-[320px] shadow-md border-none"
        style={{
          background:
            "linear-gradient(109.63deg, rgba(62, 138, 255, 0.1) 2.24%, rgba(62, 138, 255, 0.02) 58.43%)",
        }}
      >
        <CardBody className="lg:px-8 md:px-6 px-5 lg:py-10 md:py-8 py-5">
          <div className="flex flex-col justify-between h-full gap-6">
            <span className="flex flex-col gap-6">
              <h1 className="text-3xl md:text-4xl dark:text-white font-medium text-[#171663] leading-tight max-w-[373px] w-full font-open-sans">
                Install extension and{" "}
                <span className="text-[#3E8AFF]">give it a try</span>
              </h1>
              <p className="text-[#171663] dark:text-white font-normal text-sm leading-relaxed opacity-50 max-w-[291px] w-full font-open-sans">
                Find anyones email or phone number on LinkedIn in one click.
              </p>
            </span>
            <Button
              className="w-fit bg-[#3E8AFF] text-sm tracking-tight text-white shadow-md hover:bg-[#2f7ffa]"
              endContent={<Plus className="w-3.5 h-3.5" />}
            >
              Download
            </Button>
          </div>
        </CardBody>
        <Image
          src={`${
            theme === "light" ? "/assets/left.svg" : "/assets/left-dark.svg"
          }`}
          alt="left-image"
          width={230}
          height={230}
          className="bottom-0 right-[-1px] absolute xl:block hidden"
          priority
        />
      </Card>

      {/* Connect CRM Card */}
      <Card
        className="flex-1 relative min-h-[320px] shadow-md border-none"
        style={{
          background:
            "linear-gradient(109.63deg, rgba(62, 138, 255, 0.1) 2.24%, rgba(62, 138, 255, 0.02) 58.43%)",
        }}
      >
        <CardBody className="lg:px-8 md:px-6 px-5 lg:py-10 md:py-8 py-5">
          <div className="flex flex-col justify-between h-full gap-6">
            <span className="flex flex-col gap-6">
              <h1 className="text-3xl md:text-4xl text-[#171663] dark:text-white font-medium leading-tight font-open-sans">
                Reveal contacts &{" "}
                <span className="text-[#3E8AFF] font-open-sans">
                  link your CRM
                </span>
              </h1>
              <p className="text-[#171663] dark:text-white text-sm font-normal leading-relaxed opacity-50 max-w-[291px] w-full font-open-sans">
                Go to any LinkedIn Sales Navigator query or profile to reveal
                contact info and export the results to your CRM.
              </p>
            </span>
            <Button
              variant="bordered"
              className="w-fit dark:text-white text-[#171663] border dark:border-white border-[#171663] text-sm tracking-tight shadow-md"
              endContent={<Plus className="w-3.5 h-3.5" />}
            >
              Link
            </Button>
          </div>
        </CardBody>
        <Image
          src={`${
            theme === "light" ? "/assets/right.svg" : "/assets/right-dark.svg"
          }`}
          alt="right-image"
          width={230}
          height={230}
          className="-bottom-3 right-0 absolute xl:block hidden"
          priority
        />
      </Card>
    </div>
  );
};
