interface CreditTypeBarProps {
  type: string;
  color: string;
  value: number;
  total: number;
}

export const CreditTypeBar = ({
  type,
  color,
  value,
  total,
}: CreditTypeBarProps) => (
  <div className="flex justify-between items-center p-3 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
    <div className="flex items-center gap-3">
      <div className={`w-3 h-3 ${color} rounded-full`}></div>
      <span className="text-sm">{type}</span>
    </div>
    <span className="text-sm font-medium">
      {value} / {total}
    </span>
  </div>
);
