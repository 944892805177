import { AlertCircle } from "lucide-react";

type ErrorProps = {
  title?: string;
  message?: string;
  error?: any;
  onRetry?: () => void;
};

export const Error = ({
  title = "Error",
  message = "Cleanlist.ai has encountered an error while loading the app. Please try again later.",
  error,
  onRetry,
}: ErrorProps) => {
  return (
    <div className="w-full min-h-[400px] flex items-center justify-center">
      <div className="bg-[#3E8AFF0A] border border-[#1716631A] rounded-[14px] p-8 max-w-lg w-full">
        <div className="flex flex-col items-center text-center gap-6">
          {/* Error Icon */}
          <div className="bg-[#FF32321A] w-[64px] h-[64px] rounded-full flex items-center justify-center">
            <AlertCircle className="w-8 h-8 text-[#FF3232]" />
          </div>

          {/* Error Content */}
          <div className="space-y-3">
            <h3 className="text-[24px] leading-[32.4px] font-open-sans font-medium text-[#171663] dark:text-white">
              {title}
            </h3>
            <p className="text-[14px] leading-[18.9px] font-open-sans font-normal text-[#171663] dark:text-white opacity-60">
              {message}
            </p>
            {error && (
              <div className="mt-4 p-3 bg-[#1716630D] rounded-lg">
                <code className="text-[12px] font-open-sans text-[#171663] dark:text-white opacity-80">
                  {error.toString()}
                </code>
              </div>
            )}
          </div>

          {/* Retry Button */}
          {onRetry && (
            <button
              onClick={onRetry}
              className="bg-[#3E8AFF] text-white font-open-sans font-medium text-[14px] rounded-[8px] px-6 py-3 hover:bg-[#3E8AFF]/90 transition-colors"
            >
              Try Again
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
